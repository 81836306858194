<template>
    <div class="modal add_clients" v-if="modelValue" :class="{ active : modelValue, show_sidebar : addType > 0 && addType < 3 }">
        <div class="modal_container add_types" v-if="addType == 0">
            <h2>Add Clients <button class="close_btn" @click="closeModal"><i class="fas fa-times"></i></button></h2>
            <ul>
                <li>
                    <div class="card_item" @click="addType = 1">
                        <img src="@/assets/images/sms.svg">
                        <h4>Manual</h4>
                        Add A Contact
                    </div>
                </li>
                <li>
                    <div class="card_item" @click="addType = 2">
                        <img src="@/assets/images/mail.svg">
                        <h4>Upload</h4>
                        Upload A CSV
                    </div>
                </li>
                <li>
                    <div class="card_item" @click="addType = 3">
                        <img src="@/assets/images/template.svg">
                        <h4>Sync</h4>
                        With A Platform
                    </div>
                </li>
            </ul>
            <div class="status_box p-0 border-0 mt-5 mb-3">
                <div class="status">
                    <h6><span>{{ membership.total_contacts }} | {{ membership.contacts_limit }} <label>Contact spaces used.</label></span> <span><label>Spaces available:</label> {{ membership.contacts_space }}</span></h6>
                    <div class="bar"><span :style="`width: ${ membership.contacts_used }%;`"></span></div>
                </div>
            </div>
        </div>
        <manual-component :add-contact-watcher="addType" :close-modal="closeModal" v-if="addType == 1" />
        <upload-component :close-modal="closeModal" v-if="addType == 2" />
        <sync-component :add-contact-watcher="addType" :close-modal="closeModal" v-if="addType == 3" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState } from 'vuex'

    const ManualComponent = defineAsyncComponent(() => import('@/pages/contact/components/add-contact/Manual'))
    const UploadComponent = defineAsyncComponent(() => import('@/pages/contact/components/add-contact/Upload'))
    const SyncComponent = defineAsyncComponent(() => import('@/pages/contact/components/add-contact/Sync'))

    export default {
        name: 'Add Contact Wizard',

        data () {
            return  {
                addType: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            ManualComponent,
            UploadComponent,
            SyncComponent,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                vm.addType = 0;
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            membership: state => state.authModule.membership,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            toggleModal (type) {
                const vm = this;

                vm[type] = true;
            },
        },
    };
</script>

<style scoped>
    .close_btn {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    .modal.show_sidebar{
        left: 100px;
    }
    .add_clients {
        z-index: 15;
    }
</style>
